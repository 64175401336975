import "./Contact.css";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import purpleCheckmark from "../../assets/purple-coche.png";
import purplebigCheckmark from "../../assets/big-purple-coche.png";
import pinkCheckmark from "../../assets/pink-coche.png";
import pinkBigCheckmark from "../../assets/big-pink-coche.png";
import { ThemeContext } from "../../services/ThemeContext";
import miniCurt from "../../assets/mini-curt.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

function Contact() {
  let { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [state, handleSubmit] = useForm("xzbnzpdj");
  const [checkmarkMobile, setCheckmarkMobile] = useState(Boolean);
  const h2Class = theme === "light" ? "light-h2" : "dark-h2";
  const colorForm = theme === "light" ? "light-form" : "dark-form";
  const cocheSrc = checkmarkMobile && theme === "light" ? purpleCheckmark : checkmarkMobile && theme !== "light" ? pinkCheckmark : !checkmarkMobile && theme === "light" ? purplebigCheckmark : pinkBigCheckmark;
  const buttonClass = theme === "light" ? "dark-button" : "light-button";
  const propForm = ["firstName", "lastName", "email", "companyName"];

  useEffect(() => {
    window.innerWidth < 500 && setCheckmarkMobile(true);
    function handleScroll(e) {
      if (window.innerWidth < 500) {
        setCheckmarkMobile(true);
      } else {
        setCheckmarkMobile(false);
      }
    }
    window.addEventListener("resize", handleScroll);
    return () => window.removeEventListener("resize", handleScroll);
  }, [checkmarkMobile]);

  gsap.registerPlugin(useGSAP, ScrollTrigger);

  useGSAP(() => {
    gsap.to(".mini-curt", {
      scrollTrigger: {
        trigger: ".title-contact",
        scrub: true,
      },
      y: 15,
    });
  });

  return (
    <div id="Contact" className="container_contact">
      <h2 className={`${h2Class} title-contact`}>{t("contact.title")}</h2>
      {!state.succeeded ? (
        <div className="container-form">
          <div className="container-mini-curt">
            <img className="mini-curt" src={miniCurt} alt="mini curt" />
          </div>
          <form className="form" onSubmit={handleSubmit}>
            <div>
              {propForm.map((prop) => {
                return (
                  <div className="container-input">
                    <label htmlFor={prop}>{t("contact." + prop)} :</label>
                    <input
                      id={prop}
                      className={colorForm}
                      type="text"
                      name={prop}
                      placeholder={t("contact.placeholder." + prop)}
                      required
                    />
                    <ValidationError
                      prefix="Name"
                      field={prop}
                      errors={state.errors}
                    />
                  </div>
                );
              })}
            </div>
            <label htmlFor="message">{t("contact.text")} :</label>
            <textarea
              id="message"
              className={colorForm}
              name="message"
              placeholder={t("contact.enterText")}
              required
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
            <button
              className={buttonClass}
              type="submit"
              disabled={state.submitting}
            >
              {t("contact.submit")}
            </button>
          </form>
        </div>
      ) : (
        <div className="container-checkmark">
          <img src={cocheSrc} alt={t("contact.checkmark")} />
          <p>{t("contact.checkmarkMessage")}</p>
        </div>
      )}
    </div>
  );
}

export default Contact;
