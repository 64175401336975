const FR = {
  aboutMe: {
    presentation: `Lors de mon alternance chez SUEZ Consulting, j'ai eu l'opportunité de travailler sur plusieurs projets, où j'ai échangé directement avec les clients pour comprendre leurs besoins. J'ai également collaboré en équipe via GitHub et GitLab. La majorité des projets utilisaient la stack MEAN, et j'ai été amené à résoudre divers problèmes, tels que l'affichage incorrect de certaines canalisations sur un graphique ou la rédaction d'un script pour réorganiser une base de données. J'ai aussi participé à toutes les phases de création d'une application, depuis la modélisation des besoins avec UML jusqu'au déploiement avec Docker.\n
                   Actuellement, j'ai choisi de mettre mes compétences au service de l'association WeAreSwissborg (WASB), où je collabore avec d'autres développeurs pour la mise en place de leur site web. Ce projet me permet de renforcer mes connaissances en SQL, TypeScript, React, et j'ai également découvert de nouveaux composants à implémenter dans le web3, comme WalletConnect, qui permet l'authentification via un wallet Ternoa.\n
                   De plus, j'ai développé un script de scraping pour récupérer des données importantes, qui sont ensuite utilisées pour être automatiquement publiées sur différents réseaux sociaux ou pour alimenter la chaîne YouTube "Swissborg Mania".`,
    title: "À PROPOS DE MOI"
  },
  contact: {
    checkmarkMessage: "Je suis reconnaissant pour votre e-mail et vous assure une réponse dans les meilleurs délais.",
    checkmark: "coche",
    enterText: "Entrer votre texte...",
    firstName: "Prénom",
    email: "Email",
    lastName: "Nom",
    companyName: "Nom Entreprise",
    text: "Message",
    submit: "Envoyer",
    title: "CONTACTEZ-MOI",
    placeholder: {
      firstName: "Entrer votre prénom",
      email: "Entrer votre email",
      lastName: "Entrer votre nom",
      companyName: "Entrer le nom de votre entreprise",
    }
  },
  header: {
    moon: "lune",
    sun: "soleil"
  },
  main: {
    developer: "DÉVELOPPEUR ",
    iconGithub: "icône Github",
    iconLinkedin: "icône Linkedin",
    iconMail: "icône mail",
    presentation: `Grâce à plusieurs années d'expérience enrichissantes acquises avec les écoles OpenClassrooms et Iscod ` +
                  `j'ai pu intégrer l'entreprise SUEZ Consulting, qui m'a permis de collaborer en équipe sur différents projets, d'entrer en contact avec les clients pour répondre à leurs besoins. ` +
                  `A la suite de ces années d'expériences acquises, j'ai décidé de participer à des projets open-source et de mettre en place des projets personnels pour développer mes compétences, ` +
                  `comme ce portfolio où vous allez pouvoir retrouver mes réalisations ainsi que les étapes par lesquelles je suis passé, qui m'ont été bénéfiques pour ma carrière professionnelle.`,
  },
  navBar: {
    aboutMe: "Mon parcours",
    contact: "Contact",
    home: "Accueil",
    iconContact: "icône contact",
    iconHome: "icône maison",
    iconInformation: "icône information",
    iconTasks: "icône tâches réalisées",
    projects: "Projets"
  },
  projects: {
    cards: {
      groupomania: "Ma mission développer un réseau social interne allant du frontend au backend.\n\n J'ai mis en place :\n\n • MERN stack,\n • des hooks,\n • des règles de l'OWASP,\n • une API REST,\n • les routes pour communiquer entre les serveurs,\n • des données de connexion sécurisées...",
      nsm: "Projet personnel dédié à la gestion de ses investissements et inclut des articles fondés sur l'économie\n\n J'ai mis en place :\n\n • ExcelJS,\n • Cerbot,\n • Chart.js,\n • un VPS,\n • les API coingecko et twelvedata...",
      piiquante: "Ma mission de mettre en place la partie back-end d'une application web de critique de sauces piquantes.\n\n J'ai mis en place :\n\n • MongoDB, Express, Bcrypt, \n • liaison entre 2 servers,\n • différentes routes pour l'API,\n • différents rôles,\n • authentification des requêtes...",
      weAreSwissborg: "Projet associatif dédié à rassembler, aider les personnes dans le monde de la crypto.\n\n J'ai mis en place :\n\n • l'API Quill, \n • la section blog,\n • la refonte du site web,\n • la section profile,\n • l'utilisation d'un ORM,\n • une sécurité lors de l'authentification avec walletConnect..."
    },
    title: "PROJETS"
  }
}

export default FR;