import "./Projects.css";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import DataCards from "../../services/DataCards";
import { ThemeContext } from "../../services/ThemeContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Icons GitHub, link site and circular arrow
import githubIcon from "../../assets/purple-github.png";
import siteIcon from "../../assets/purple-icon-site.png";
import turnIcon from "../../assets/purple-icon-turn.png";
import nextArrow from "../../assets/next-arrow.png";
import darkNextArrow from "../../assets/dark-next-arrow.png";
import prevArrow from "../../assets/prev-arrow.png";
import darkPrevArrow from "../../assets/dark-prev-arrow.png";

function Projects() {
  let { t } = useTranslation();
  const cards = DataCards;
  const [toTurn, setToTurn] = useState(undefined);
  const { theme } = useContext(ThemeContext);
  const [displayFront, setDisplayFront] = useState(true);

  const addColor = (value) => {
    const h2Class = theme === "light" ? "dark-h2 dark-h2-tablet" : "light-h2 light-h2-tablet";
    const cardClass = theme === "light" ? "card light-card" : "card dark-card";
    const build = theme === "light" ? "dark-in-build" : "light-in-build";
    const containerImg = theme === "light" ? "container-img-project dark-container-img" : "container-img-project light-container-img";
    const filterBackground = theme !== "light" ? "dark-design_projects" : "";

    if (value === "titleH2") {
      return h2Class;
    } else if (value === "card") {
      return cardClass;
    } else if (value === "build") {
      return build;
    } else if (value === "containerImg") {
      return containerImg;
    } else if (value === "background") {
      return filterBackground;
    }
  };

  // Create Card;
  let card = cards.map((card) => {
    const cardClasses = `${addColor("card")} ${!displayFront && toTurn === card.id ? "turned-card" : (displayFront && toTurn === card.id) || toTurn !== card.id ? "init-card" : ""}`;
    const frontCardClasses = `front-card ${!displayFront && toTurn === card.id ? "hide-front-card" : (displayFront && toTurn === card.id) || toTurn !== card.id ? "init-front-card" : ""}`;
    const backCardClasses = `back-card ${!displayFront && toTurn === card.id ? "display-back-card" : (displayFront && toTurn === card.id) || toTurn !== card.id ? "init-back-card" : ""}`;

    return (
      <div key={card.id}>
        <div className={cardClasses}>
          <div className={frontCardClasses}>
            <div className={addColor("containerImg")}>
              <img src={card.url_image} alt={card.alt} />
            </div>
            <div className="container-details-card">
              <h3>{card.title}</h3>
              <div className="container-stack">
                {displayStacks(card.stacks)}
              </div>
              <div className="container-links">
                <div>
                  <a href={card.lien_github} target="_blanket">
                    <img
                      className="icon-card"
                      src={githubIcon}
                      alt="lien github"
                    />
                  </a>
                </div>
                <div>
                  <a
                    className="link-site"
                    href={card.lien_site}
                    target="_blanket"
                  >
                    <img className="icon-card" src={siteIcon} alt="lien site" />
                  </a>
                </div>
                <div>
                  <button
                    className="turn-button"
                    onClick={() => turnCard(card.id, false)}
                  >
                    <img
                      className="icon-card"
                      src={turnIcon}
                      alt="circular arrow"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={backCardClasses}>
            <p className="text-card">
              {t(`projects.cards.${card.description}`)}
            </p>
            <div>
              <button
                className="turn-button"
                onClick={() => turnCard(card.id, true)}
              >
                <img
                  className=" icon-card circularArrow"
                  src={turnIcon}
                  alt="circular arrow"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  });

  function displayStacks(stacks) {
    let listStacks = stacks.map((stack, index) => (
      <img className="icon-card" key={index} src={stack.icon} alt={stack.alt} />
    ));
    return listStacks;
  }

  function turnCard(id, checkSide) {
    setToTurn(id);
    setDisplayFront(checkSide);
  }

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <img
        className={className}
        src={theme === "light" ? nextArrow : darkNextArrow}
        alt=""
        onClick={onClick}
        style={{
          transform: "scale(3.5)",
          height: "auto",
          right: "-40px",
        }}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <img
        className={className}
        src={theme === "light" ? prevArrow : darkPrevArrow}
        alt=""
        onClick={onClick}
        style={{
          transform: "scale(3.5)",
          height: "auto",
          left: "-40px",
        }}
      />
    );
  }

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 500,
    dots: true,
    nextArrow: <SampleNextArrow className="next-arrow" />,
    prevArrow: <SamplePrevArrow className="prev-arrow" />,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 910,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container_projects">
      <div className={`container-design_projects ${addColor("background")}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="waveSVG"
        >
          <path
            fill="#ffffff"
            fill-opacity="1"
            d="M0,32L30,74.7C60,117,120,203,180,202.7C240,203,300,117,360,112C420,107,480,181,540,218.7C600,256,660,256,720,234.7C780,213,840,171,900,138.7C960,107,1020,85,1080,90.7C1140,96,1200,128,1260,149.3C1320,171,1380,181,1410,186.7L1440,192L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
          ></path>
        </svg>
        <div className="background-projects" />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#ffffff"
            fill-opacity="1"
            d="M0,32L40,37.3C80,43,160,53,240,53.3C320,53,400,43,480,58.7C560,75,640,117,720,133.3C800,149,880,139,960,122.7C1040,107,1120,85,1200,85.3C1280,85,1360,107,1400,117.3L1440,128L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
          ></path>
        </svg>
      </div>
      <div id="Projects" className="sub-container_projects">
        <h2 className={addColor("titleH2")}>{t("projects.title")}</h2>
        <div className="container-slider">
          <Slider {...settings}>{card}</Slider>
        </div>
      </div>
    </div>
  );
}

export default Projects;
