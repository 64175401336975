import './AboutMe.css';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../services/ThemeContext';

function AboutMe() {
    let { t } = useTranslation();
    const { theme } = useContext(ThemeContext);
    const h2Class = theme === 'light' ? 'light-h2' : 'dark-h2';
    const textClass = theme === 'light' ? 'dark-text' : 'light-text';

    return (
        <div id='AboutMe' className='container_about-me'>
            <h2 className={h2Class}>{t('aboutMe.title')}</h2>
            <div className='container-presentation'>
                <p className={textClass}>
                    {t('aboutMe.presentation')}
                </p>
            </div>
        </div>
    )
}

export default AboutMe;