import './NavBar.css'
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../services/ThemeContext';

// Images
import whiteHome from '../../assets/white-home.png';
import whiteProjects from '../../assets/white-projects.png';
import whiteAboutMe from '../../assets/white-about_me.png';
import whiteContact from '../../assets/white-contact.png';
import greyHome from '../../assets/grey-home.png';
import greyProjects from '../../assets/grey-projects.png';
import greyAboutMe from '../../assets/grey-about_me.png';
import greyContact from '../../assets/grey-contact.png';

function NavBar () {
    let { t } = useTranslation();
    let { theme } = useContext(ThemeContext);

    const addColor = (value, srcImg = []) => {
        const src = theme === 'light' ? srcImg[0] : srcImg[1];
        const NavClass = theme === 'light' ? 'grey-background' : 'white-background';

        if(value === 'nav') {
            return NavClass;
        } else if(value === 'img') {
            return src;
        }
    }

    return (
        <nav className={addColor('nav')}>
            <ul className='ul_icon'>
                <li><a href="#Main"><img src={addColor('img', [whiteHome, greyHome])} alt={t('navBar.iconHome')} className='icons-nav' /></a></li>
                <li><a href="#Projects"><img src={addColor('img', [whiteProjects, greyProjects])} alt={t('navBar.iconTasks')} className='icons-nav' /></a></li>
                <li><a href="#AboutMe"><img src={addColor('img', [whiteAboutMe, greyAboutMe])} alt={t('navBar.iconInformation')} className='icons-nav' /></a></li>
                <li><a href="#Contact"><img src={addColor('img', [whiteContact, greyContact])} alt={t('navBar.iconContact')} className='icons-nav' /></a></li>
            </ul>

            <ul className='ul_text'>
                <li><a className='light-text' href="#Main">{t('navBar.home')}</a></li>
                <li><a className='light-text' href="#Projects">{t('navBar.projects')}</a></li>
                <li><a className='light-text' href="#AboutMe">{t('navBar.aboutMe')}</a></li>
                <li><a className='light-text' href="#Contact">{t('navBar.contact')}</a></li>
            </ul>
        </nav>
    )
}

export default NavBar;