const EN = {
    aboutMe: {
        presentation: `During my internship at SUEZ Consulting, I had the opportunity to work on several projects, where I directly communicated with clients to understand their needs. I also collaborated with the team using GitHub and GitLab. Most of the projects utilized the MEAN stack, and I was tasked with solving various issues, such as incorrect display of certain pipelines on a graph or writing a script to reorganize a database. I also participated in all phases of the application development process, from requirements modeling with UML to deployment with Docker.\n
                       Currently, I have chosen to put my skills to use for the WeAreSwissborg (WASB) association, where I collaborate with other developers to set up their website. This project has allowed me to strengthen my knowledge in SQL, TypeScript, and React, and I have also explored new components to implement in web3, such as WalletConnect, which enables authentication via a Ternoa wallet.\n
                       Additionally, I developed a scraping script to collect important data, which is then used to automatically post on different social media platforms or to help feed content to the YouTube channel "Swissborg Mania.`,
        title: "ABOUT ME"
    }, 
    contact: {
        checkmarkMessage: "I am grateful for your email and assure you a response at the earliest convenience.",
        checkmark: "checkmark",
        enterText: "Enter your text...",
        email: "Email",
        firstName: "First Name",
        lastName: "Last Name",
        companyName: "Company Name",
        text: "Message",
        submit: "Submit",
        title: "CONTACT ME",
        placeholder: {
            firstName: "Enter your first name",
            email: "Enter your email",
            lastName: "Enter your last name",
            companyName: "Enter your company name"
        }
    },
    header: {
        moon: "moon",
        sun: "sun"
    },
    main: {
        developer: "DEVELOPER ",
        iconGithub: "Github icon",
        iconLinkedin: "Linkedin icon",
        iconMail: "mail icon",
        presentation: `Thanks to several enriching years of experience gained through OpenClassrooms and Iscod, ` + 
                  `I had the opportunity to join the company SUEZ Consulting, which allowed me collaborated with teams on various projects and interact with clients to meet their needs. ` +
                  `Following these years of experience, I decided to get involved in open-source projects and work on personal projects to further enhance my skills, ` +
                  `such as this portfolio where you can find my achievements and the steps I have taken, which have been beneficial to my professional career.`,
    },
    navBar: {
        aboutMe: "About me",
        contact: "Contact",
        home: "Home",
        iconContact: "contact icon",
        iconHome: "home icon",
        iconInformation: "information icon",
        iconTasks: "tasks completed icon",
        projects: "Projects"
    },
    projects: {
        cards: {
            groupomania: "I was tasked with developing an internal social network covering everything from frontend to backend.\n\n I set up :\n\n • MERN stack,\n • hooks,\n • OWASP rules,\n • a REST API,\n • routes to communicate between servers\n • secure login data...",
            nsm: "A personal project dedicated to investment management and will include articles based on economics.\n\n I set up :\n\n • ExcelJS,\n • Cerbot,\n • Chart.js,\n • un VPS,\n • API coingecko and twelvedata...",
            piiquante: "I was tasked with implementing the back-end part of a web application for reviewing hot sauces.\n\n I set up :\n\n • MongoDB, Express, Bcrypt,\n • connection between 2 servers,\n • various routes for the API,\n • different roles,\n • authentication of requests...",
            weAreSwissborg: "A community-driven project dedicated to bringing together and supporting people in the crypto world.\n\n I set up :\n\n • the Quill API,\n • the blog section,\n • redesign of the website,\n • the profile section,\n • use of an ORM,\n • add of tables in SQLite3,\n • secured authentication with WalletConnect...",
        },
        title: "PROJECTS"
    }
}

export default EN;