import './Main.css';
import Header from '../../common/Header/Header';
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../services/ThemeContext';
import Lottie from "lottie-react";

// Images
import greyGitHub from '../../assets/grey-full-github.png';
import greyLinkedin from '../../assets/grey-linkedin.png';
import greyMail from '../../assets/grey-mail.png';
import iscodLogo from '../../assets/logo-iscod.jpg';
import openClassroomLogo from '../../assets/logo_open_classroom.png';
import suezLogo from '../../assets/Logo Suez.png';
import wave from '../../assets/wave.json';
import darkWave from '../../assets/dark-wave.json';

function Main() {
  let { t } = useTranslation();
  let { theme } = useContext(ThemeContext);
  const lottieRef = useRef();
  lottieRef.current?.setSpeed(2);

  const addColor = (value) => {
    const waveBg = theme === 'light' ? 'linear-gradient(rgb(255, 255, 255) 70%, rgb(157, 78, 221) 92%)' : 'linear-gradient(rgb(81, 81, 81) 70%, rgb(16 0 43) 92%)';
    const h1Class = theme === 'light' ? 'light-h1' : 'dark-h1';
    const iconClass = theme === 'light' ? 'dark-icon' : 'light-icon';
    const h2Class = theme === 'light' ? 'dark-h2 dark-h2-tablet' : 'light-h2 light-h2-tablet';
    const textClass = theme === 'light' ? 'dark-text' : 'light-text';
    const logoClass = theme === 'light' ? 'dark-logo' : 'light-logo';

    if(value === 'waveBg') {
      return waveBg;
    } else if(value === 'titleH1') {
      return h1Class;
    } else if(value === 'img') {
      return iconClass;
    } else if(value === 'titleH2') {
      return h2Class;
    } else if(value === 'logo') {
      return logoClass;
    } else {
      return textClass;
    }
  }

  return (
    <div id="Main" className="main">
      <Header/>
      <Lottie
        className='lottie-wave'
        animationData={theme === 'light' ? wave : darkWave}
        loop={false}
        style={{
          background: addColor('waveBg'),
        }}
        lottieRef={lottieRef}
      />
      <div className='container-title'>
        <h1 className={addColor('titleH1')}>
          FANCHONNA Curtis <br/>
          <span className='design-part-text-h1'>
            {t('main.developer')}
            <span className='no-wrap-fullstack'>
              FULL-STACK
            </span>
          </span>
        </h1>
        <div className='container-icons'>
          <a href='https://github.com/KeotiseuF'>
            <img src={greyGitHub} alt={t('main.iconGithub')} className={addColor('img')} />
          </a>
          <a href='https://www.linkedin.com/in/f-curtis/'>
            <img src={greyLinkedin} alt={t('main.iconLinkedin')} className={addColor('img')} />
          </a>
          <a href='mailto:f.curtis.dev@gmail.com'>
            <img src={greyMail} alt={t('main.iconMail')} className={addColor('img')} />
          </a>
        </div>
      </div>
      <div className='container-presentation'>
        <div className='container-logo'>
          <img src={iscodLogo} alt='ISCOD logo'/>
          <img src={openClassroomLogo} alt="OpenClassrooms logo" />
          <img className={addColor('logo')} src={suezLogo} alt="SUEZ logo" />
        </div>
        <p className={`${addColor('text')} text-presentation`}>
          {t('main.presentation')}
        </p>
      </div>
    </div>
  )
}

export default Main;